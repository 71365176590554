import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

//Components
import Main from '../../components/Emprendimientos/main'
import About from '../../components/Emprendimientos/about'
import Steps from '../../components/Emprendimientos/steps'
import Investors from '../../components/investors'
import Tasacion from "../../components/Home/tasacion"
import BannerTop from "../../components/Global/Modules/MediaPush/BannerTop"

const IndexPage = () => (
  <Layout>
    <Seo title="Emprendimientos" description="Sección de emprendimientos" />
    <BannerTop page="emprendimientos"/>
    <div className="div-developments">
      <About />
      <Investors />
      {/* <Steps /> */}
      <Tasacion />
    </div>
  </Layout>
)

export default IndexPage
