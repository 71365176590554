import React, {useEffect, useRef, useState} from 'react' 
import OwlCarousel from 'react-owl-carousel3';
import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import {getPhotos,getDescription,getQuality,getCover } from '../../helpers/helper.rendering'
import { graphql, useStaticQuery } from 'gatsby';
import {getDevelopmentsFilterAction, updateFiltersDevelopment} from '../../redux/developmentsDucks'
import { getReverseOrder,getFeatured, getName, getFakeAddress, getLocation } from '../../helpers/helper.developments';
import { connect } from 'react-redux'
import { Link } from '@reach/router';
import Slider from 'react-slick'
import useQueryDevelopments from '../../hooks/useQueryDevelopments';
import Loading from '../Loading';




export function Main({api_key,developmentsFilters,dispatch,developments}) {
    const [filter,setFilter] = useState(0)

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
        }
    }`)
    
    const settingsSlick = {
        dots: true,
        infinite: true,
        speed: 500,
        fade:false,
        dotsClass:"my-dots-class",
        slidesToShow: 1,
        slidesToScroll: 1
      };

    const slider = useRef()

    const options = {
        settings: {
            overlayColor: "rgba(0, 0, 0, 0.9)",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableWheelControls:true
        },
        buttons: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            iconColor: "rgba(255,255,255, 1)",
        },
        caption: {
            captionColor: "#000",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
            captionTextTransform: "uppercase",
        }
        };
        useEffect(() => {
            if(api_key){	
                dispatch(updateFiltersDevelopment(filter))
                dispatch(getDevelopmentsFilterAction()) // Get all developments
            }
        },[filter,api_key])

        const { allDevelopmentsData, isLoading } = useQueryDevelopments()

       
  return  (
        <div id="section-galery" className='a dev-main'>
            {isLoading 
                ?  <Loading />
                :   <div class="" id="dev-secuencial">
                        <div class="slider" id="dev-gallery">
                        <Slider  ref={slider} id="prop-gallery" {...settingsSlick}>
                            {getReverseOrder(getFeatured(allDevelopmentsData?.map((element) => element))).map((development, index)=>(
                                <div class="item banner-main banner-ficha emprendimiento">
                            
                                    <img src={getCover(getPhotos(development)).original} className='object-cover object-center cursor-pointer' alt={realEstate?.name} />
                                    <div class="info-banner position-absolute">
                                        <div class="info row align-items-center text-center justify-content-center">
                                            <div class="col-8 col-lg-12">
                                                <h1>{getName(development)}</h1>
                                                <h4>                                      
                                                    { development.location.short_location.replace('Argentina |', '')}       
                                                </h4>                                            
                                                <a href={"/emprendimientos/" + development.id + (development.unities ? '?unidades=' + makeUrlUnities(development.unities) : '')} class="btn-global btn-tertiary mt-3 mt-lg-5"><span class="d-block d-lg-inline">CONOCÉ MÁS</span></a>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="arrows-owl">
                            <div className="arrow" onClick={() => slider.current.slickPrev()}> <div className='next'>{"<"}</div></div>
                            <div className="arrow " onClick={() => slider.current.slickNext()}> <div className='next'>{">"}</div> </div>
                        </div>
                    </div>
                </div>
            }
        </div>
  )
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
    developments: state.developments.developments,
}),null)(Main);