import React, { useEffect } from "react";
import Developments from './developments'
import Main from './main'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';
import { connect } from "react-redux";
import { useLocation } from "@reach/router";

function About({actions}) {

    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                name
                sections {
                    developments {
                        about {
                            title
                            subtitle_1
                            subtitle_2
                        }
                    }
                }
            }
        }`
    )

    const location = useLocation()

    useEffect(() => {
        if(location.pathname.includes('/emprendimientos')) {
            checkActionsTop()
        }
    },[location])

    const checkActionsTop = () => {
        if (actions && actions.length > 0) {
            const filteredActions = actions.filter(a => a.small_type === "VEN" || a.small_type === "IEN" || a.small_type === "SIE").map(a => [a.section, a.small_type].join())
            // console.log(filteredActions); - // ['Emprendimientos,VEN']
            if (filteredActions.length > 0) {
                const filteredbyPage = filteredActions.filter(p => p.includes("Emprendimientos"))
                return (filteredbyPage.length > 0)
                    ? ((filteredbyPage[0].split(',')[0] === "Emprendimientos") || null) : null
            }
        } else {
            return null
        }
    }

  return (
    <section class="development-about">
        {!checkActionsTop() ? <Main /> : null }
        <div class="development-title container-fluid">
            <div class="row">
                <div className="col-lg-12 ps-5">
                    <Breadcrumbs
                        props={[
                        { name: realEstate.name, route: "/", location: "" },
                        { name: "Emprendimientos", route:   "", location: ""},
                        ]}
                    />
                </div>
                <div class="col-lg-12 d-flex flex-column justify-content-center text-center mt-3">
                    <h2>
                        {realEstate.sections.developments.about.title}
                        {/* <!-- Nuestros procedimientos ofrecen resultados de la mejor calidad que garantizan el éxito de tu inversión. --> */}
                    </h2>
                    <p className="mt-4">{realEstate.sections.developments.about.subtitle_1}<br/>{realEstate.sections.developments.about.subtitle_2}</p>
                </div>
            </div>
        </div>
        <Developments />
        {/* <?php include('components/development/developments-featured.php') ?> */}
    </section>
  )}

export default connect(state => ({
    actions: state.actions.actions,
}),null)(About);