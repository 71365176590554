import React, { useEffect, useState } from "react"
import Development from "../development-card"
import Loading from "../Loading"

import { connect } from "react-redux"
import {
  getDevelopmentsFilterAction,
  updateFiltersDevelopment,
} from "../../redux/developmentsDucks"
import { getReverseOrder } from "../../helpers/helper.developments"
import useQueryDevelopments from "../../hooks/useQueryDevelopments"

const DICCIONARIO = {
  "VER TODOS": 1,
  "PRE-VENTA": 2,
  "EN POZO": 3,
  "EN CONSTRUCCIÓN": 4,
  "TERMINADO": 5,
}

const Developments = ({ developmentsFilters, loading, api_key, dispatch }) => {
//   const [filter, setFilter] = useState(0)
  const [filter, setFilter] = useState("VER TODOS")

  const [buttons, setButtos] = useState([
    {
      name: "VER TODOS",
      value: 0,
    },
    {
      name: "EN POZO",
      value: 3,
    },
    {
      name: "EN CONSTRUCCIÓN",
      value: 4,
    },
    {
      name: "TERMINADOS",
      value: 6,
    },
  ])

  //   useEffect(() => {
  //     if (api_key) {
  //         dispatch(updateFiltersDevelopment(filter))
  //         dispatch(getDevelopmentsFilterAction()) // Get all developments
  //     }
  // }, [filter, api_key])

  const { allDevelopmentsData, isLoading } = useQueryDevelopments()

  const getSingularDevelopments = (developments, group) => {
    //Devuelve los emprendimientos sin la etiqueta que agrupa
    const developments_singular = []
    if (developments === undefined) {
      return []
    }
    for (const development of developments) {
      developments_singular.push(development)
      // if (
      //   development.custom_tags.find(
      //     element => element.group_name?.toLowerCase() === group?.toLowerCase()
      //   )
      // ) {
      // } else {
      // }
    }
    console.log(developments_singular)
    if (filter.toLowerCase() === "ver todos") {
      return developments_singular
    }
    return developments_singular.filter(
      d =>
        d.construction_status === filter ||
        convertStatusIdToString(d.construction_status).toLowerCase() ===
          filter.toLowerCase()
    ) // Nuevo por strings
  }

  const getStatusByDevelopments = () => {
    let status = [
      {
        name: "VER TODOS",
        value: 0,
      },
    ]
    if (allDevelopmentsData && !isLoading) {
      for (const iterator of allDevelopmentsData) {
        if (
          !status.find(
            s =>
              s?.name?.toLowerCase() ===
              convertStatusIdToString(
                iterator?.construction_status
              ).toLowerCase()
          ) &&
          convertStatusIdToString(
            iterator?.construction_status
          ).toLowerCase() !== "desconocido"
        ) {
          status.push({
            name: convertStatusIdToString(iterator.construction_status),
            value: status.length + 1,
          })
        }
      }
    }
    return status
  }

  const convertStatusIdToString = statusId => {
    switch (statusId) {
      case 1:
        return "DESCONOCIDO"
      case 3:
        return "EN POZO"
      case 4:
        return "EN CONSTRUCCIÓN"
      case 6:
        return "TERMINADO"
      default:
        return statusId.toUpperCase()
    }
  }

  useEffect(() => {
    if (allDevelopmentsData) {
      setButtos(getStatusByDevelopments())
    }
  }, [allDevelopmentsData])

  return (
    <section id="featured-devs" class="pt-lg-5">
      <div className="container-fluid my-5 pb-lg-3">
        <div className="d-flex flex-wrap justify-content-center">
          {/* {buttons.map((button, index) => (
            <button
              className={
                "button-filter mb-2 mb-lg-0 " +
                (filter === button.value ? " active " : "")
              }
              onClick={() => setFilter(button.value)}
            >
              {button.name}
            </button>
          ))} */}
          {isLoading ? (
                    <>
                      {[1, 2, 3, 4].map(button => (
                        <button
                          className={"button-filter mb-2 mb-lg-0 skeleton "}
                        >
                          XXXXXXXX
                        </button>
                      ))}
                    </>
                  ) : (
                    buttons
                      .sort((a, b) => DICCIONARIO[a.name] - DICCIONARIO[b.name])
                      .map(
                        (button, index) => (
                          <button
                            className={
                              "button-filter text-uppercase mb-2 mb-lg-0 " +
                              (filter === button.name ? " active " : "")
                            }
                            onClick={() => setFilter(button.name)}
                          >
                            {button.name}
                          </button>
                        )
                        // )
                      )
                  )}
        </div>
      </div>
      <div class="container-fluid">
        <div class="row no-gutters mt-5 mt-lg-1">
          {
            allDevelopmentsData && !isLoading
              ? getSingularDevelopments(getReverseOrder(
                  allDevelopmentsData.map(element => element)
                )).map((development, index) => (
                  <div class="col-lg-4 mb-3 mb-lg-4 pb-lg-2 px-lg-3">
                    <Development key={index} development={development} />
                  </div>
                ))
              : [1, 2, 3, 4, 5, 6].map(index => (
                  <div class="col-lg-4 mb-3 mb-lg-4 pb-lg-2 px-lg-3">
                    <Development key={index} skeleton={true} />
                  </div>
                ))
            // :<Loading />
          }
        </div>
      </div>
    </section>
  )
}
export default connect(
  state => ({
    developmentsFilters: state.developments.developmentsFilters,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
  }),
  null
)(Developments)
