import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';

const Steps = () => {

    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                sections {
                    developments{
                        steps{
                            texts{
                                id,
                                title,
                                paragraph
                            }
                        }
                    }
                }
            }
        }`
    )
    return (
        <section class="development-steps">
            <div class="container-fluid">
                <div class="col-lg-10 offset-lg-2">
                    <div class="row no-gutters d-flex justify-content-between align-items-stretch">
                        <div class="col-lg-4 d-flex pb-4 justify-content-between">
                            <div class="content">
                                <div class="row">
                                    <div class="col-4 col-lg-12">
                                        <h1>{realEstate.sections.developments.steps.texts[0].id}</h1>
                                    </div>
                                    <div class="col-8 col-lg-12">
                                        <h5>{realEstate.sections.developments.steps.texts[0].title}</h5>
                                            <p>
                                                {realEstate.sections.developments.steps.texts[0].paragraph}
                                            </p>
                                    </div>
                                </div>
                            </div>
                            <div class="border-separator"></div>
                        </div>
                        <div class="col-lg-4 pl-lg-5 d-flex pb-4 justify-content-between">
                            <div class="content">
                                <div class="row">
                                    <div class="col-4 col-lg-12">
                                        <h1>{realEstate.sections.developments.steps.texts[1].id}</h1>
                                    </div>
                                    <div class="col-8 col-lg-12">                                
                                        <h5>{realEstate.sections.developments.steps.texts[1].title}</h5>
                                        <p>
                                            {realEstate.sections.developments.steps.texts[1].paragraph}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="border-separator"></div>
                        </div>
                        <div class="col-lg-4 d-flex pl-lg-5 pb-4">
                            <div class="content border-none">
                                <div class="row">
                                    <div class="col-4 col-lg-12">
                                        <h1>{realEstate.sections.developments.steps.texts[2].id}</h1>
                                    </div>
                                    <div class="col-8 col-lg-12">
                                        <h5>
                                            {realEstate.sections.developments.steps.texts[2].title}
                                        </h5>
                                        <p>
                                            {realEstate.sections.developments.steps.texts[2].paragraph}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Steps;